import clsx from "clsx";

function HdOnlineLed({
  online,
  className,
}: {
  online: boolean;
  className?: string;
}) {
  return (
    <span
      className={clsx(
        "w-2.5 h-2.5 bg-gray-300 rounded-full flex-shrink-0 inline-block",
        online && "bg-primary",
        className
      )}
    />
  );
}

export default HdOnlineLed;
