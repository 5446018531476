import { formatRelative } from "date-fns";
import { it } from "date-fns/locale";
import { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

import { ContactConnectionInfo } from "../../types/model/shared/contact-connection-info";
import { SignalrChatMessage } from "../../types/model/shared/signalr-chat-message";
import { useNotifications } from "./notifications.store";

function Notifications() {
  const ref = useRef<HTMLUListElement>(null);
  const { items, setRead, setOpen, open } = useNotifications();

  useEffect(() => {
    if (open) {
      ref.current?.focus();
    }
  }, [open]);

  useEffect(() => {
    ref.current?.addEventListener("focusout", () => {
      if (!ref.current?.matches(":focus-within")) {
        setOpen(false);
      }
    });
  }, [setOpen, ref]);

  return (
    <ul
      tabIndex={0}
      ref={ref}
      className="select-none align-center shadow-xl bg-white w-64 text-bodyText border-gray-200 border outline-none"
    >
      {items.length === 0 ? (
        <div className="py-4 text-center px-3">
          Non sono presenti nuovi messaggi
        </div>
      ) : (
        <>
          {items
            .sort((a, b) => b.date.getTime() - a.date.getTime())
            .slice(0, 4)
            .map((item) => (
              <li
                key={item.date.getTime()}
                className={`py-2 flex items-center justify-between border-b border-b-gray-300 w-full overflow-hidden hover:opacity-80 cursor-pointer even:bg-[#F2F2F2]`}
                onClick={() => {
                  setRead(item.date);
                }}
              >
                <div className={`px-3 flex items-center w-full `}>
                  {/* <div
                    className={`w-7 h-7 flex-shrink-0 rounded-full flex items-center justify-center mr-3 bg-secondary text-white`}
                  >
                    <i
                      className={`fa ${
                        item.read ? "fa-envelope-open" : "fa-envelope"
                      }`}
                    ></i>
                  </div> */}
                  <div className={` flex-grow overflow-hidden`}>
                    <div className="text-xs font-bold">
                      {item.type === "message"
                        ? (item.content as SignalrChatMessage).SenderFullName
                        : (item.content as ContactConnectionInfo).FullName}
                    </div>
                    <div className="text-xs  text-ellipsis text-nowrap overflow-hidden">
                      {item.type === "message"
                        ? (item.content as SignalrChatMessage).Text
                        : (item.content as ContactConnectionInfo).IsClosed
                        ? "Disconnesso"
                        : "Connesso"}
                    </div>
                    <div className="text-xs font-bold">
                      {formatRelative(item.date, new Date(), {
                        locale: it,
                      })}{" "}
                      - {item.companyName}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          <div className="py-2 text-center px-3 bg-[#F2F2F2]">
            <NavLink className="underline text-xs" to={"/conversation-hub"}>
              Vai all'hub conversazioni
            </NavLink>
          </div>
        </>
      )}
    </ul>
  );
}

export default Notifications;
