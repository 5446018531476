import { I18nProvider } from "react-aria-components";

import { HttpClientInterceptorProvider } from "@hd/http-client";
import { ToastWrapper } from "@hd/toast";
import { HdPageLoader } from "@hd/ui-kit";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import AppRoutes from "./AppRoutes";
import { useLoader } from "./core/loader/loader.store";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  // const { init } = useAuth();
  const loading = useLoader((s) => s.active > 0);

  return (
    <I18nProvider locale="it-IT">
      <HttpClientInterceptorProvider>
        {loading && <HdPageLoader />}
        <QueryClientProvider client={queryClient}>
          <AppRoutes></AppRoutes>
          <ToastWrapper></ToastWrapper>
          {import.meta.env.VITE_SHOW_QUERY_DEVTOOLS == 1 && (
            <div
              style={{
                fontSize: "16px",
              }}
            >
              <ReactQueryDevtools />
            </div>
          )}
        </QueryClientProvider>
      </HttpClientInterceptorProvider>
    </I18nProvider>
  );
}

export default App;
