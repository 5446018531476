import clsx from "clsx";
import { PropsWithChildren, useCallback, useEffect } from "react";

interface HdOffcanvasProps {
  isOpen: boolean;
  position: "left" | "right";
  width?: string;
  className?: string;
  closeOnClickOutside?: boolean;
  closeOnEsc?: boolean;
  allowScroll?: boolean;
  onClose?: () => void;
  backdrop?: boolean;
}

function HdOffcanvas({
  className,
  isOpen,
  position,
  width,
  closeOnClickOutside = true,
  closeOnEsc = true,
  allowScroll = false,
  onClose,
  backdrop,
  children,
}: PropsWithChildren<HdOffcanvasProps>) {
  const handleClose = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  const onClickOutside = useCallback(() => {
    if (!closeOnClickOutside) return;

    if (isOpen) {
      if (handleClose) handleClose();
    }
  }, [closeOnClickOutside, isOpen, handleClose]);

  const onEscKey = useCallback(
    (event: KeyboardEvent) => {
      if (!closeOnEsc) return;

      if (event.key === "Escape") {
        if (isOpen) {
          if (handleClose) handleClose();
        }
      }
    },
    [closeOnEsc, isOpen, handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", onEscKey, false);
    return () => document.removeEventListener("keydown", onEscKey);
  }, [onEscKey]);

  useEffect(() => {
    document.addEventListener("click", onClickOutside, false);
    return () => document.removeEventListener("click", onClickOutside);
  }, [onClickOutside]);

  useEffect(() => {
    if (!allowScroll) {
      if (isOpen) document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen, allowScroll]);

  return (
    <>
      <div
        role="dialog"
        style={{ width: width }}
        aria-modal="true"
        onClick={(ev) => ev.stopPropagation()}
        className={clsx(
          "bg-white fixed bottom-0 top-0 z-50 outline-none transition transform duration-300 ease-in-out shadow-xl",
          position === "left" ? "left-0" : "right-0",
          isOpen
            ? "translate-x-0"
            : position === "left"
            ? "translate-x-[-100%]"
            : "translate-x-[100%]",
          className
        )}
      >
        {children}
      </div>
      {backdrop && (
        <div
          className={clsx(
            "fixed top-0 left-0 w-full h-full z-40 opacity-0 bg-[#00000005] invisible transition-[visibility] duration-200 ease-in-out ",
            isOpen ? "visible opacity-100" : ""
          )}
        />
      )}
    </>
  );
}

export default HdOffcanvas;
