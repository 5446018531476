import axios from "axios";
import { PropsWithChildren, useCallback, useEffect } from "react";

import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";

import { ContactConnectionInfo } from "../../types/model/shared/contact-connection-info";
import { SignalrChatMessage } from "../../types/model/shared/signalr-chat-message";
import { useAuth } from "../auth/auth.store";
import { useChatStore } from "../chat/chat.store";
import { useNotifications } from "../notifications/notifications.store";

export function ChatProvider(props: PropsWithChildren) {
  const { pageCompanyId, username, activeCompanies } = useAuth();
  const { push } = useNotifications();
  const { init, onOfflinePerson, onOnlinePerson } = useChatStore();

  const subscribeToNotifications = useCallback(async () => {
    // console.log("running subscribeToNotifications");

    const result = activeCompanies.map(async (company) => {
      const hubName = `c${company.id}`;
      const userId = `${company.id}~${username}~${1}`;

      const companyName =
        activeCompanies.find((c) => c.id === company.id)?.name || "";

      // console.log("connecting to hub " + hubName);

      const connection = new HubConnectionBuilder()
        .withUrl(
          import.meta.env.VITE_HD_CHAT_OPERATOR_URL +
            `/api?userId=${userId}&hub=${hubName}`
        )
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

      await connection.start();

      // console.log("connected to hub " + hubName);

      await axios.post(
        `${
          import.meta.env.VITE_HD_CHAT_OPERATOR_URL
        }/api/operators/join?userId=${userId}&hub=${hubName}`
      );

      // console.log("joined operators group for hub " + hubName);

      connection.on("newMessage", (data: SignalrChatMessage) => {
        // contact message
        if (data.UserType === 0) {
          push({
            type: "message",
            content: data,
            date: new Date(),
            read: false,
            companyName,
          });
        } else {
          // operator message
          // system message
        }
      });

      connection.on("personStatusUpdated", (data: ContactConnectionInfo) => {
        if (!data.IsClosed) {
          onOnlinePerson(Number(data.PersonId));
        } else {
          onOfflinePerson(Number(data.PersonId));
        }

        push({
          type: "message",
          content: data,
          date: new Date(),
          read: false,
          companyName,
        });
      });

      return connection;
    });

    return await Promise.all(result);
  }, [username, activeCompanies, push, onOfflinePerson, onOnlinePerson]);

  useEffect(() => {
    //const companyIds = activeCompanies.map((company) => company.id) as number[];

    const connections: HubConnection[] = [];

    subscribeToNotifications().then((c) => {
      connections.push(...c);
    });

    return () => {
      // clean connection subscribing
      connections.forEach((c) => c.stop());
      // console.log("remove event listener");
    };
  }, [activeCompanies, subscribeToNotifications]);

  useEffect(() => {
    if (pageCompanyId != null) init(pageCompanyId);
  }, [init, pageCompanyId]);

  return <>{props.children}</>;
}
