import { PropsWithChildren, useEffect } from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "./auth.store";
import { AuthenticationStatus } from "./types/authentication-status";

export function PrivateRoute(props: PropsWithChildren) {
  const { authStatus, init } = useAuth();

  useEffect(() => {
    if (authStatus === AuthenticationStatus.Loading) {
      init();
    }
  }, [init, authStatus]);

  return authStatus === AuthenticationStatus.Loading ? (
    <></>
  ) : (
    <>
      {authStatus === AuthenticationStatus.Authenticated ? (
        props.children
      ) : (
        <Navigate to="/account/login" />
      )}
    </>
  );
}
