import { create } from "zustand";

export interface SidebarState {
  open: boolean;
  opening: boolean;
  closing: boolean;
  currentUrl: string;
  toggleOpen: () => void;
  setCurrentUrl: (url: string) => void;
}

const useSidebarStore = create<SidebarState>((set) => {
  return {
    currentUrl: "",
    open: true,
    opening: false,
    closing: false,
    toggleOpen: () =>
      set((state) => ({
        open: !state.open,
        opening: !state.open,
        closing: state.open,
      })),
    setCurrentUrl: (url: string) =>
      set((state) => ({ ...state, currentUrl: url })),
  };
});

export const useSidebar = () => {
  return {
    isOpen: useSidebarStore((state) => state.open),
    toggleOpen: useSidebarStore((state) => state.toggleOpen),
    isOpening: useSidebarStore((state) => state.opening),
    isClosing: useSidebarStore((state) => state.closing),
    currentUrl: useSidebarStore((state) => state.currentUrl),
    setCurrentUrl: useSidebarStore((state) => state.setCurrentUrl),
  };
};
