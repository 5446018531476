import { create } from "zustand";

import { ListItemDto } from "../../types/dto/shared/list-item.dto";
import * as AuthApi from "./auth.api";
import { AuthenticationStatus } from "./types/authentication-status";
import { SectionItem } from "./types/section-item";

interface AuthState {
  authStatus: AuthenticationStatus;
  pageCompanyId: number | null;
  loggedUserData: {
    userId: string;
    username: string;
    userFullName: string;
    sections: SectionItem[];
    activeGroupName: string;
    activeGroups: ListItemDto[];
    activeCompanies: ListItemDto[];
    editUsersAndPermissions: boolean;
  } | null;
  token: string | null;
  error: string;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  init: () => Promise<void>;
  changeGroup: (id: number) => Promise<void>;
  forgotPassword: (email: string) => Promise<boolean>;
  resetPassword: (token: string, newPassword: string) => Promise<boolean>;
  setToken: (token: string | null) => void;
  setPageCompanyId: (id: number) => void;
}

export const useAuthStore = create<AuthState>()(
  // persist(
  (set) => {
    return {
      authStatus: AuthenticationStatus.Loading,
      token: null,
      loggedUserData: null,
      error: "",
      pageCompanyId: null,
      setToken: (token: string | null) => set(() => ({ token })),
      setPageCompanyId: (id: number) => {
        set(() => ({ pageCompanyId: id }));
      },
      init: async () => {
        try {
          const result = await AuthApi.refreshToken();

          set(() => ({
            token: result.jwtToken,
          }));

          // workaround: interceptor now are recreated, but just too late to get the new token
          const meResult = await AuthApi.me(result.jwtToken);

          set(() => ({
            authStatus: AuthenticationStatus.Authenticated,
            error: "",
            loggedUserData: {
              userId: meResult.userId,
              username: meResult.username,
              userFullName: meResult.userFullName,
              sections: meResult.sections,
              activeGroupName: meResult.activeGroupName,
              activeCompanies: meResult.activeCompanies,
              activeGroups: meResult.activeGroups,
              editUsersAndPermissions: meResult.editUsersAndPermissions,
            },
          }));
        } catch {
          set(() => ({
            authStatus: AuthenticationStatus.Unauthenticated,
            token: null,
          }));
        }
      },

      changeGroup: async (id: number) => {
        await AuthApi.changeGroup(id);

        window.location.reload();
      },
      login: async (username: string, password: string) => {
        try {
          const loginResult = await AuthApi.login(username, password);

          set(() => ({
            token: loginResult.jwtToken,
          }));

          const meResult = await AuthApi.me(loginResult.jwtToken);
          // mappo i dati della api in quello che mi serve mettere nello state

          set(() => ({
            authStatus: AuthenticationStatus.Authenticated,
            error: "",
            loggedUserData: {
              userId: meResult.userId,
              username: meResult.username,
              userFullName: meResult.userFullName,
              sections: meResult.sections,
              activeGroupName: meResult.activeGroupName,
              activeCompanies: meResult.activeCompanies,
              activeGroups: meResult.activeGroups,
              editUsersAndPermissions: meResult.editUsersAndPermissions,
            },
          }));
        } catch (error: unknown) {
          set(() => ({
            authStatus: AuthenticationStatus.Unauthenticated,
            token: null,
            error:
              error instanceof Error
                ? error.message
                : "Si è verificato un errore imprevisto",
          }));
        }
      },
      logout: async () => {
        try {
          await AuthApi.logout();
        } finally {
          set(() => ({
            authStatus: AuthenticationStatus.Unauthenticated,
            loggedUserData: null,
            error: "",
          }));
        }
      },
      forgotPassword: async (email: string) => {
        try {
          await AuthApi.forgotPassword(email);
          return true;
        } catch (error: unknown) {
          set(() => ({
            authStatus: AuthenticationStatus.Unauthenticated,
            token: null,
            error:
              error instanceof Error
                ? error.message
                : "Si è verificato un errore imprevisto",
          }));
          return false;
        }
      },
      resetPassword: async (token: string, newPassword: string) => {
        try {
          await AuthApi.resetPassword(newPassword, token);
          return true;
        } catch (error: unknown) {
          set(() => ({
            authStatus: AuthenticationStatus.Unauthenticated,
            token: null,
            error:
              error instanceof Error
                ? error.message
                : "Si è verificato un errore imprevisto",
          }));
          return false;
        }
      },
    };
  }
  // {
  //   name: "auth",
  //   storage: createJSONStorage(() => sessionStorage),
  //   partialize: (state) => ({
  //     token: state.token,
  //   }),
  // }
  // )
);

export const useAuth = () => {
  const {
    init,
    login,
    logout,
    loggedUserData,
    authStatus,
    error,
    token,
    pageCompanyId,
    setPageCompanyId,
    setToken,
    changeGroup,
    forgotPassword,
    resetPassword,
  } = useAuthStore();

  return {
    activeGroups: loggedUserData?.activeGroups || [],
    sections: loggedUserData?.sections || [],
    editUsersAndPermissions: loggedUserData?.editUsersAndPermissions || false,
    activeCompanies: loggedUserData?.activeCompanies || [],
    activeGroupName: loggedUserData?.activeGroupName || "",
    username: loggedUserData?.username || "",
    userId: loggedUserData?.userId || "",
    authStatus,
    error,
    token,
    pageCompanyId,
    setPageCompanyId,
    init,
    login,
    logout,
    forgotPassword,
    changeGroup,
    resetPassword,
    setToken,
  };
};
