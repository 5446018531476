import { useCallback } from "react";
import toast, { ToastBar, Toaster } from "react-hot-toast";

import { useNotifications } from "../../core/notifications/notifications.store";

function ToastWrapper() {
  const { setOpen } = useNotifications();

  const dimissToast = useCallback(
    (toastId: string) => {
      toast.dismiss(toastId);
      setOpen(true);
    },
    [setOpen]
  );
  return (
    <Toaster>
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <div
              className="flex text-center w-full items-center text-sm"
              onClick={() => dimissToast(t.id)}
            >
              {icon}
              {message}
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}

export default ToastWrapper;
