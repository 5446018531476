import { lazy, Suspense } from "react";
import { Route } from "react-router-dom";

import { HdPageLoader } from "@hd/ui-kit";

const ConversationHubPage = lazy(
  () => import("./pages/conversation-hub/ConversationHubPage")
);
function usePersonsRoutes() {
  return (
    <Route
      path="/conversation-hub"
      element={
        <Suspense fallback={<HdPageLoader />}>
          <ConversationHubPage />
        </Suspense>
      }
    />
  );
}

export default usePersonsRoutes;
