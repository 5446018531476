import { Outlet } from "react-router-dom";

import { useAuth } from "../auth/auth.store";
import ContactPanel from "../contact-panel/ContactPanel";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";

function Layout() {
  const {
    logout,
    changeGroup,
    userId,
    username,
    activeCompanies,
    activeGroupName,
    activeGroups,
    sections,
    editUsersAndPermissions,
  } = useAuth();

  return (
    <div className="flex flex-col h-full flex-grow">
      <Header
        username={username}
        userId={userId}
        activeCompanies={activeCompanies}
        activeGroupName={activeGroupName}
        activeGroups={activeGroups}
        editUsersAndPermissions={editUsersAndPermissions}
        logout={logout}
        changeGroup={changeGroup}
      />
      <div className="flex flex-grow relative">
        <Sidebar items={sections} />
        <div className="flex-grow bg-[#f2f2f2] flex flex-col">
          <Outlet></Outlet>
        </div>
      </div>
      <Footer></Footer>
      <ContactPanel></ContactPanel>
    </div>
  );
}

export default Layout;
