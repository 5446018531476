import { BrowserRouter, Route, Routes } from "react-router-dom";

import { PrivateRoute } from "./core/auth/PrivateRoute";
import { ChatProvider } from "./core/chat/ChatProvider";
import LayoutPage from "./core/layout/Layout";
import LegacyHdBridgePage from "./core/legacy-hd-bridge/LegacyHdBridgePage";
import useLoginRoutes from "./features/login/useLoginRoutes";
import usePersonsRoutes from "./features/persons/usePersonsRoutes";

function AppRoutes() {
  const LoginRoutes = useLoginRoutes();
  const PersonsRoutes = usePersonsRoutes();
  return (
    <BrowserRouter>
      <Routes>
        {LoginRoutes}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <ChatProvider>
                <LayoutPage />
              </ChatProvider>
            </PrivateRoute>
          }
        >
          {PersonsRoutes}
          <Route path="/" element={<LegacyHdBridgePage />} />
          <Route path="*" element={<LegacyHdBridgePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
