import { create } from "zustand";

export interface ContactPanelState {
  isOpen: boolean;
  contactId: number | null;
  selectedTabId: number;
  open(contactId: number, selectedTabId: number): void;
  close(): void;
  changeTab(selectedTabId: number): void;
}

export const useContactPanel = create<ContactPanelState>((set) => {
  return {
    isOpen: false,
    contactId: null,
    selectedTabId: 0,
    open: (contactId: number, selectedTabId: number) =>
      set(() => ({ isOpen: true, contactId, selectedTabId })),
    close: () => set(() => ({ isOpen: false })),
    changeTab: (selectedTabId: number) => set(() => ({ selectedTabId })),
  };
});
