import { httpClient, SILENT_REQUEST_HEADER_KEY } from "@hd/http-client";
import { useQuery } from "@tanstack/react-query";

import { ContactDto } from "../../types/dto/contact/contact.dto";

type QueryParams = {
  contactId: number | null;
};

const fetchData = async (params: QueryParams): Promise<ContactDto> => {
  const result = await httpClient.get<ContactDto>(
    import.meta.env.VITE_HD_API_URL + `/contact/${params.contactId}/`,
    { headers: { [SILENT_REQUEST_HEADER_KEY]: "true" } }
  );

  return result.data;
};

export const useGetContactDetails = (params: QueryParams) => {
  return useQuery({
    // staleTime: 10000 * 60,
    queryKey: ["contactDetails", params],
    queryFn: () => fetchData(params),
    enabled: !!params.contactId,
  });
};
