import { httpClient } from "@hd/http-client";

import { AuthenticationResultDto } from "../../types/dto/auth/authentication-result.dto";
import { RootSectionDto } from "../../types/dto/auth/root-section.dto";
import { ListItemDto } from "../../types/dto/shared/list-item.dto";

export const REFRESH_TOKEN_URL =
  import.meta.env.VITE_HD_API_URL + "/auth/refresh-token";

export async function me(token?: string) {
  const result = await httpClient.get<{
    userId: string;
    username: string;
    userFullName: string;
    activeGroupName: string;
    sections: RootSectionDto[];
    activeGroups: ListItemDto[];
    activeCompanies: ListItemDto[];
    editUsersAndPermissions: boolean;
  }>(import.meta.env.VITE_HD_API_URL + "/users/me", {
    withCredentials: true,
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : undefined,
  });

  const section = result.data.sections.find(
    (s) => s.sectionTypeName === "Persone"
  );

  // TODO: mock
  section?.sections.push({
    sectionName: "Hub conversazioni",
    sectionTypeName: "Persone",
    sectionTypePosition: 1,
    sectionTypeIcon: "fa-gear",
    sectionUrl: "conversation-hub",
    childSections: [],
    sectionID: 99999,
    sectionPosition: 1,
    sectionTypeID: 9999,
  });

  // teoricamente dovrebbe farlo la API
  return {
    ...result.data,
    sections: result.data.sections
      .filter((s) => s.sectionTypeName !== "Settings")
      .sort((a, b) => (a.sectionTypePosition < b.sectionTypePosition ? -1 : 1))
      .map((r) => ({
        name: r.sectionTypeName,
        children: r.sections
          .sort((a, b) => (a.sectionPosition < b.sectionPosition ? -1 : 1))
          .map((s) => ({
            name: s.sectionName,
            path: s.sectionUrl,
            children: s.childSections
              .sort((a, b) => (a.position < b.position ? -1 : 1))
              .map((c) => ({
                name: c.name,
                path: c.url,
              })),
          })),
      })),
  };
}

export async function refreshToken() {
  const result = await httpClient.post<AuthenticationResultDto>(
    REFRESH_TOKEN_URL,
    {},
    {
      withCredentials: true,
    }
  );

  return result.data;
}

export async function login(username: string, password: string) {
  // 1) login with legacy provider to obtain the cookie for legacy hd
  // await loginLegacy(username, password);

  // 2) login with api provider to obtain the token for api consumption
  const loginResult = await httpClient.post<{
    username: string;
    jwtToken: string;
  }>(
    import.meta.env.VITE_HD_API_URL + "/auth/authenticate",
    { username, password },
    {
      withCredentials: true,
    }
  );

  // 3) get the user context
  // const meResult = await me();
  return { jwtToken: loginResult.data.jwtToken };
}

export async function logout() {
  await httpClient.post<AuthenticationResultDto>(
    import.meta.env.VITE_HD_API_URL + "/auth/revoke-token",
    {},
    {
      withCredentials: true,
    }
  );
}

export async function changeGroup(id: number) {
  await httpClient.post<void>(
    import.meta.env.VITE_HD_API_URL + "/auth/change-group/" + id,
    {},
    {
      withCredentials: true,
    }
  );
}

export async function forgotPassword(email: string) {
  await httpClient.post<void>(
    import.meta.env.VITE_HD_API_URL + "/auth/forgot-password/" + email,
    {}
  );
}

export async function resetPassword(newPassword: string, token: string) {
  await httpClient.post<void>(
    import.meta.env.VITE_HD_API_URL + "/auth/reset-password/",
    {
      newPassword,
      token,
    }
  );
}
