import { useCallback, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useScript } from "usehooks-ts";

import logo_small from "../../../assets/logo_small.svg";
import menu_toggle from "../../../assets/sidebar_toggler_icon_grey.png";
import { ListItemDto } from "../../../types/dto/shared/list-item.dto";
import Notifications from "../../notifications/Notifications";
import { useNotifications } from "../../notifications/notifications.store";
import { useSidebar } from "../sidebar/sidebar.store";
import HeaderDropdownMenu, { DropdownItem } from "./HeaderDropdownMenu";

interface HeaderProps {
  logout: () => void;
  changeGroup: (id: number) => void;
  editUsersAndPermissions: boolean;
  userId: string;
  username: string;
  activeGroups: ListItemDto[];
  activeCompanies: ListItemDto[];
  activeGroupName: string;
}

function Header({
  userId,
  logout,
  changeGroup,
  editUsersAndPermissions,
  activeGroups,
  activeGroupName,
}: HeaderProps) {
  const [showSettings, setShowSettings] = useState(false);
  const [settingsItems, setSettingsItems] = useState<DropdownItem[]>([]);

  const [showAccounts, setShowAccounts] = useState(false);
  const [accountsItems, setAccountsItems] = useState<DropdownItem[]>([]);

  const init = useCallback(() => {
    setSettingsItems([
      { text: "Impostazioni", url: "/Settings" },
      { text: "Gestione Account Email", url: "/MailAccountGroup" },
      {
        text: "Acquista crediti",
        url: "/ExtraMailPurchased/Create",
      },
      { text: "Informazioni legali", url: "/LegalNotice" },
    ]);

    const _items: DropdownItem[] = [];
    if (editUsersAndPermissions) {
      _items.push({ text: "Utenti e Permessi", url: "/Users" });
    }
    _items.push(
      ...[
        {
          text: "Modifica account",
          url: "/Users/Edit/" + userId,
        },
        {
          text: "Pagina di riepilogo",
          url: "/",
        },
        {
          text: "Disconnettimi",
          callback: () => {
            logout();
          },
        },
      ]
    );
    if (activeGroups.length > 0) {
      const item: DropdownItem = {
        text: "Usa hoteldoor come",
        children: [],
      };
      activeGroups.forEach((group) => {
        item.children?.push({
          text: group.name,
          // url: "/Home/ChangeGroup/" + group.id,
          callback: () => {
            changeGroup(group.id);
          },
        });
      });
      _items.push(item);
    }

    setAccountsItems(_items);
  }, [userId, logout, changeGroup, editUsersAndPermissions, activeGroups]);

  useEffect(() => {
    init();
  }, [init]);

  useScript(`beamerprovider.js`, {
    removeOnUnmount: true,
    id: "beamer-init",
  });

  useScript(`https://app.getbeamer.com/js/beamer-embed.js`, {
    removeOnUnmount: true,
    id: "beamer",
  });

  const location = useLocation();

  const { toggleOpen: toggleOpenSidebar, isOpen: openSidebar } = useSidebar();
  const {
    itemsToReadCount,
    // push,
    open,
    toggleOpen: toggleOpenNotifications,
  } = useNotifications();

  return (
    <div
      className="pr-4 flex items-center z-10 text-secondary sticky top-0 bg-white"
      style={{ boxShadow: "0 1px 10px 0 rgba(50,50,50,.2)" }}
    >
      <div
        className={
          "ml-1 w-10 flex flex-col items-center md:w-48 md:flex-row md:ml-4 flex-shrink-0 " +
          (openSidebar ? "" : "!w-10 !ml-1 !flex-col")
        }
      >
        <NavLink
          to="/"
          onClick={(e) => {
            if (location.pathname === "/") {
              e.preventDefault();
            }
          }}
        >
          <img
            className={
              "md:ml-12 w-6 md:w-8 " + (openSidebar ? "" : " !ml-0 !w-6")
            }
            src={logo_small}
          ></img>
        </NavLink>
        <img
          className={
            "md:ml-16 mt-1 md:mt-0 opacity-60 hover:opacity-100 cursor-pointer " +
            (openSidebar ? "" : " !ml-0 !mt-1")
          }
          src={menu_toggle}
          onClick={() => toggleOpenSidebar()}
        />
      </div>
      <div className="text-sm font-thin ml-5">
        <span className="hidden md:inline mr-2">
          Stai utilizzando hoteldoor come
        </span>
        <span className="font-bold">{activeGroupName}</span>
      </div>
      <div className="ml-auto flex items-center">
        <div className="flex items-center relative group">
          <div
            className={
              "p-5 h-full cursor-pointer hover:bg-slate-50 " +
              (open ? "bg-slate-50" : "")
            }
            onClick={() => toggleOpenNotifications()}
          >
            <i className="fa fa-envelope-o fa-lg relative">
              {itemsToReadCount > 0 && (
                <div className="font-sans absolute inline-flex items-center justify-center px-1 min-w-4 h-4 text-xxs font-semibold text-white bg-accent rounded-full -top-3 -end-3">
                  {itemsToReadCount}
                </div>
              )}
            </i>
          </div>
          <div
            className={`absolute top-full mt-1 left-0 ${
              open ? "block" : "hidden"
            }`}
          >
            <Notifications />
          </div>
        </div>
        <div
          className="flex items-center relative group"
          // onMouseEnter={() => setShowSettings(true)}
          // onMouseLeave={() => setShowSettings(false)}
        >
          <div
            className="py-5 px-3 md:p-5 h-full flex items-center cursor-pointer hover:bg-slate-50"
            onClick={() => setShowAccounts(true)}
          >
            <i className="fa fa-user fa-lg "></i>
          </div>

          <HeaderDropdownMenu
            items={accountsItems}
            open={showAccounts}
            setOpen={(value) => setShowAccounts(value)}
          ></HeaderDropdownMenu>
        </div>
        <div
          className="flex items-center relative group"
          // onMouseEnter={() => setShowSettings(true)}
          // onMouseLeave={() => setShowSettings(false)}
        >
          <div
            className="py-5 px-3 md:p-5 h-full flex items-center cursor-pointer hover:bg-slate-50"
            onClick={() => setShowSettings(true)}
          >
            <i className="fa fa-gear fa-lg"></i>
          </div>
          <HeaderDropdownMenu
            items={settingsItems}
            open={showSettings}
            setOpen={setShowSettings}
          ></HeaderDropdownMenu>
        </div>
        <NavLink
          to="/Calendar"
          className="py-5 px-3 md:p-5 h-full flex items-center cursor-pointer hover:bg-slate-50"
        >
          <i className="fa fa-calendar fa-lg  "></i>
        </NavLink>

        <div className="py-5 px-3 md:p-5 h-full flex items-center cursor-pointer hover:bg-slate-50 App__Beamer">
          <i className="fa fa-bell fa-lg "></i>
        </div>
        <div
          className="py-5 px-3 md:p-5 h-full flex items-center cursor-pointer hover:bg-slate-50"
          onClick={logout}
        >
          {/* <i className="icon-login text-base"></i> */}
          <i className="fa fa-sign-out fa-lg "></i>
        </div>
      </div>
    </div>
  );
}

export default Header;
