import { createElement } from "react";
import toast from "react-hot-toast";

export const showNewNotification = (message: string) => {
  toast(message, {
    position: "bottom-right",
    duration: 5000,
    icon: createElement("i", { className: "fa fa-envelope" }),
    className: "notification-toast",
  });
};

export const showErrorFeedback = (message: string) => {
  toast.error(message, {
    position: "bottom-right",
    duration: 5000,
    className: "notification-toast",
  });
};

export const showSuccessFeedback = (message: string) => {
  toast.success(message, {
    position: "bottom-right",
    duration: 5000,
    className: "notification-toast",
  });
};
