import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import {
  ComboBox,
  Input,
  Key,
  ListBox,
  ListBoxItem,
  ListBoxItemProps,
  Popover,
} from "react-aria-components";
import { FaSearch } from "react-icons/fa";
import { FaSpinner } from "react-icons/fa6";
import { MdClear } from "react-icons/md";
import { useDebounceCallback } from "usehooks-ts";

interface HdAutocompleteProps<T extends object> {
  children: (item: T) => React.ReactNode;
  getItemKey: (item: T) => Key;
  itemDescription: (item: T) => string;
  items?: Iterable<T>;
  getItems?: (value: string) => Promise<Iterable<T>>;
  onItemSelected?: (item: T | null) => void;
  selectedItem?: T;
  minLength?: number;
  disabled?: boolean;
  placeholder?: string;
}

function HdAutocomplete<T extends object>({
  children,
  items,
  getItems,
  onItemSelected,
  selectedItem,
  minLength = 3,
  getItemKey,
  disabled,
  placeholder,
  itemDescription,
}: HdAutocompleteProps<T>) {
  if (items === undefined && getItems === undefined) {
    throw new Error("HdAutocomplete: items or getItems is required");
  } else if (items !== undefined && getItems !== undefined) {
    throw new Error(
      "HdAutocomplete: items and getItems are mutually exclusive"
    );
  }

  const [filteredItems, setFilteredItems] = useState(items);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const search = useCallback(
    async (text: string) => {
      if (getItems) {
        const remoteItems = await getItems(text);
        setFilteredItems(remoteItems);
        setLoading(false);
      }
      // TODO: gestire se ho items locali
    },
    [getItems]
  );
  const debouncedSearch = useDebounceCallback(search, 500);

  const onTextChange = useCallback(
    async (text: string) => {
      setInputValue(text);
      if (text.length >= minLength) {
        setLoading(true);
        await debouncedSearch(text);
      } else {
        setFilteredItems([]);
      }
    },
    [debouncedSearch, minLength]
  );

  const onSelectionCleared = useCallback(() => {
    setInputValue("");
    setFilteredItems([]);
    onItemSelected?.(null);

    // https://react-spectrum.adobe.com/react-aria/ComboBox.html#state
  }, [onItemSelected]);

  useEffect(() => {
    if (!selectedItem) {
      setInputValue("");
      setFilteredItems([]);
    } else {
      setInputValue(itemDescription(selectedItem));
    }
  }, [selectedItem, itemDescription]);

  return (
    <ComboBox
      className={" bg-white outline-none border border-gray-300 text-[#333]"}
      items={filteredItems}
      onInputChange={(text) => onTextChange(text)}
      isDisabled={disabled}
      allowsEmptyCollection={true}
      menuTrigger="focus"
      inputValue={inputValue}
      onSelectionChange={(key) => {
        if (!filteredItems || !onItemSelected) return;

        const selectedItem = Array.from(filteredItems).find(
          (item) => getItemKey(item) === key
        );

        if (!selectedItem) return;

        setInputValue(itemDescription(selectedItem));
        onItemSelected(selectedItem);
      }}
    >
      <div className="relative">
        <Input
          className="outline-none pl-3 pr-6 py-2 w-full placeholder:text-gray-400"
          placeholder={placeholder}
          readOnly={selectedItem != null}
        />
        {/* <Button className={"px-3"}>
          <FaAngleDown className="text-gray-400 text-xxs ml-auto" />
        </Button> */}
        <div className="absolute inset-y-0 right-2 flex items-center">
          {loading ? (
            <FaSpinner className="animate-spin"></FaSpinner>
          ) : selectedItem == null ? (
            <FaSearch className=""></FaSearch>
          ) : (
            <MdClear
              className="cursor-pointer"
              onClick={() => {
                onSelectionCleared();
              }}
            />
          )}
        </div>
      </div>
      <Popover
        className={
          "shadow-lg ring-1 ring-gray-200 entering:animate-in entering:fade-in entering:zoom-in-95 exiting:animate-out exiting:fade-out exiting:zoom-out-95 fill-mode-forwards origin-top-left"
        }
        style={{ width: "var(--trigger-width)" }}
      >
        <ListBox
          className={clsx(
            " bg-white outline-none text-sm overflow-hidden z-10"
          )}
          selectedKeys={selectedItem ? [getItemKey(selectedItem)] : []}
          renderEmptyState={() => (
            <div className="px-3 py-2">
              {inputValue.length < 3
                ? `Digita ${minLength} o più caratteri per ricercare.`
                : loading
                ? "Caricamento..."
                : "Nessun risultato."}
            </div>
          )}
        >
          {children}
        </ListBox>
      </Popover>
    </ComboBox>
  );
}

HdAutocomplete.Item = function HdAutocompleteItem({
  children,
  ...props
}: ListBoxItemProps) {
  return <ListBoxItem {...props}>{children}</ListBoxItem>;
};

export default HdAutocomplete;
