import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { PropsWithChildren, useEffect } from "react";

import { REFRESH_TOKEN_URL, refreshToken } from "../../core/auth/auth.api";
import { useAuth } from "../../core/auth/auth.store";
import { useLoader } from "../../core/loader/loader.store";
import httpClient from "./http-client";

export const SILENT_REQUEST_HEADER_KEY = "x-silent-request";

/**
 * Extracts the error message from the given error, if present.
 * @param {unknown} error The error to extract the message from.
 * @returns {string} The error message if present, otherwise "Si  verificato un errore imprevisto".
 */
const getApiErrorMessage = (error: unknown) => {
  if (error instanceof AxiosError) {
    if (error.response?.data) {
      if (typeof error.response.data === "string") {
        return error.response.data;
      } else if (typeof error.response.data === "object") {
        if (error.response.data.error) {
          return error.response.data.error;
        } else if (error.response.data.message) {
          return error.response.data.message;
        }
      }
    }
  } else if (error instanceof Error) {
    return error.message;
  }

  return "Si è verificato un errore imprevisto";
};

export const HttpClientInterceptorProvider = (props: PropsWithChildren) => {
  const { token, setToken } = useAuth();
  const { addLoader, removeLoader } = useLoader();

  useEffect(() => {
    const requestInterceptor = (config: InternalAxiosRequestConfig) => {
      if (!config.headers.get(SILENT_REQUEST_HEADER_KEY)) {
        addLoader();
      }
      //configuring header
      if (token && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    };

    const requestErrorInterceptor = (error: unknown) => Promise.reject(error);

    const responseErrorInterceptor = async (error: AxiosError) => {
      if (
        error.config?.url !== REFRESH_TOKEN_URL &&
        error.response &&
        error.response.status === 401
      ) {
        try {
          const result = await refreshToken();
          setToken(result.jwtToken);
          const originalRequest = error.config as InternalAxiosRequestConfig;
          originalRequest.headers.Authorization = `Bearer ${result.jwtToken}`;
          return axios(originalRequest);
        } catch (refreshError: unknown) {
          return Promise.reject(new Error(getApiErrorMessage(refreshError)));
        } finally {
          if (
            error.config &&
            !error.config.headers.get(SILENT_REQUEST_HEADER_KEY)
          ) {
            removeLoader();
          }
        }
      }
      if (
        error.config &&
        !error.config.headers.get(SILENT_REQUEST_HEADER_KEY)
      ) {
        removeLoader();
      }

      return Promise.reject(new Error(getApiErrorMessage(error)));
    };

    const requestInterceptorId = httpClient.interceptors.request.use(
      requestInterceptor,
      requestErrorInterceptor
    );

    const responseInterceptorId = httpClient.interceptors.response.use(
      (response) => {
        if (
          response.config &&
          !response.config.headers.get(SILENT_REQUEST_HEADER_KEY)
        ) {
          removeLoader();
        }
        return response;
      },
      responseErrorInterceptor
    );

    return () => {
      httpClient.interceptors.request.eject(requestInterceptorId);
      httpClient.interceptors.response.eject(responseInterceptorId);
    };
  }, [token, setToken, addLoader, removeLoader]);

  return props.children;
};
