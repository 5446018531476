import { create } from "zustand";

import { showNewNotification } from "@hd/toast";

import { ContactConnectionInfo } from "../../types/model/shared/contact-connection-info";
import { SignalrChatMessage } from "../../types/model/shared/signalr-chat-message";

export interface Notification {
  type: "message" | "connection-info";
  content: ContactConnectionInfo | SignalrChatMessage;
  date: Date;
  read: boolean;
  companyName: string;
}

export interface NotificationsState {
  open: boolean;
  items: Notification[];
  push: (notification: Notification) => void;
  toggleOpen: () => void;
  setOpen: (value: boolean) => void;
  setReadAll: () => void;
  setRead: (date: Date) => void;
}

const showNotification = (notification: Notification) => {
  if (notification.type === "message") {
    const content = notification.content as SignalrChatMessage;
    showNewNotification("Nuovo messaggio da " + content.SenderFullName);
  } else if (notification.type === "connection-info") {
    const content = notification.content as ContactConnectionInfo;
    showNewNotification(
      content.FullName + " si è " + content.IsClosed
        ? "disconnesso"
        : "connesso"
    );
  }
};

const useNotificationsStore = create<NotificationsState>((set) => {
  return {
    open: false,
    items: [],

    push: (notification: Notification) => {
      set((state) => ({
        items: [...state.items, notification],
      }));

      showNotification(notification);
    },

    setReadAll: () => {
      set((state) => ({
        items: state.items.map((item) => ({ ...item, read: true })),
      }));
    },

    setRead: (date: Date) => {
      set((state) => ({
        items: state.items.map((item) =>
          item.date === date ? { ...item, read: true } : item
        ),
      }));
    },

    toggleOpen: () => {
      set((state) => ({
        open: !state.open,
      }));
    },
    setOpen: (value: boolean) => {
      set(() => ({
        open: value,
      }));
    },
  };
});

export const useNotifications = () => {
  const { open, toggleOpen, setOpen, items, setRead, setReadAll, push } =
    useNotificationsStore();

  return {
    open,
    items,
    push,
    setReadAll,
    setRead,
    toggleOpen,
    setOpen,
    itemsToReadCount: useNotificationsStore(
      (state) => state.items.filter((item) => !item.read).length
    ),
  };
};
