import clsx from "clsx";
import React, { PropsWithChildren } from "react";

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";

interface TabsCommonProps extends PropsWithChildren {
  className?: string;
}

// compound component example, based on @headlessui/react
function HdTabs(
  props: TabsCommonProps & {
    selectedIndex?: number;
    onChange?: (index: number) => void;
  }
) {
  return (
    <TabGroup
      selectedIndex={props.selectedIndex}
      className={props.className}
      onChange={(index) => {
        props.onChange?.(index);
      }}
    >
      {props.children}
    </TabGroup>
  );
}

HdTabs.Header = (props: TabsCommonProps) => {
  return (
    <TabList className={props.className}>
      {React.Children.map(props.children, (child) =>
        React.isValidElement(child) ? React.cloneElement(child) : child
      )}
    </TabList>
  );
};

HdTabs.Content = (props: TabsCommonProps) => {
  return (
    <TabPanels className={props.className}>
      {React.Children.map(props.children, (child) =>
        React.isValidElement(child) ? React.cloneElement(child) : child
      )}
    </TabPanels>
  );
};

HdTabs.Tab = (
  props: TabsCommonProps & {
    disabled?: boolean;
    active?: boolean;
    render?: (props: {
      hover: boolean;
      selected: boolean;
      disabled: boolean;
      focus: boolean;
    }) => React.JSX.Element;
  }
) => {
  return (
    <Tab
      disabled={props.disabled}
      className={clsx(
        props.className,
        "outline-none group inline-block px-4 py-3 data-[selected]:text-gray-600 data-[selected]:bg-white data-[selected]:border-t-4 data-[selected]:border-t-primary data-[selected]:font-bold"
      )}
    >
      {props.children
        ? props.children
        : ({ hover, selected, disabled, focus }) =>
            props.render ? (
              props.render({ hover, selected, disabled, focus })
            ) : (
              <></>
            )}
    </Tab>
  );
};

HdTabs.Panel = (props: TabsCommonProps) => {
  return (
    <TabPanel className={clsx("p-5 bg-white", props.className)}>
      {props.children}
    </TabPanel>
  );
};

export default HdTabs;
