import loader from "../../assets/loading.gif";

function HdLoader() {
  return (
    <>
      <div className="flex w-full h-full flex-grow justify-center items-center">
        <img src={loader} />
      </div>
    </>
  );
}

export default HdLoader;
