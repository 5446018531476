import { useCallback, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

export type DropdownItem = {
  text: string;
  url?: string;
  callback?: () => void;
  children?: DropdownItem[];
};

export type HeaderDropdownMenuProps = {
  items: DropdownItem[];
  open: boolean;
  setOpen: (open: boolean) => void;
};

function HeaderDropdownMenu(props: HeaderDropdownMenuProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.addEventListener("focusout", () => {
      if (!ref.current?.matches(":focus-within")) {
        props.setOpen(false);
      }
    });
  }, [props, ref]);

  useEffect(() => {
    if (props.open) {
      ref.current?.focus();
    }
  }, [props]);

  const preClick = useCallback(
    (cb?: () => void) => {
      cb?.();
      props.setOpen(false);
    },
    [props]
  );

  return (
    props.open && (
      <div
        ref={ref}
        className={`absolute top-full mt-1 right-0 outline-none`}
        tabIndex={0}
      >
        <ul className="select-none align-center shadow-xl bg-white w-64 text-[#666] border-gray-200 border">
          {props.items.map((item, index) => (
            <li
              key={index}
              className="py-2 px-3 hover:bg-gray-100 cursor-pointer"
            >
              {item.url ? (
                <NavLink
                  className="block"
                  to={item.url}
                  onClick={() => {
                    preClick();
                    // return true;
                  }}
                >
                  {item.text}
                </NavLink>
              ) : item.callback ? (
                <div onClick={() => preClick(item.callback)}>{item.text}</div>
              ) : (
                <ul className="group/children">
                  <span>{item.text}</span>
                  <div className="hidden group-hover/children:block hover:block py-1">
                    {item.children?.map((child, index) => (
                      <li
                        key={index}
                        className="px-1 py-1 hover:bg-gray-100 cursor-pointer hover:underline"
                      >
                        {child.url && (
                          <NavLink
                            className="block"
                            to={child.url}
                            onClick={() => {
                              preClick();
                            }}
                          >
                            {child.text}
                          </NavLink>
                        )}

                        {child.callback && (
                          <div
                            className="block"
                            onClick={() => {
                              preClick();
                              child.callback?.();
                            }}
                          >
                            {child.text}
                          </div>
                        )}
                      </li>
                    ))}
                  </div>
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    )
  );
}

export default HeaderDropdownMenu;
