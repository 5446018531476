import { create } from "zustand";

import { httpClient } from "@hd/http-client";

export interface ChatState {
  onlineContactIds: number[];
  onOnlinePerson(contactId: number): void;
  onOfflinePerson(contactId: number): void;
  onlineOperators: string[];
  onOnlineOperator(username: string): void;
  onOfflineOperator(username: string): void;
  init(companyId: number): Promise<void>;
}

export const useChatStore = create<ChatState>((set, get) => {
  return {
    onlineContactIds: [],
    onlineOperators: [],
    init: async (companyId: number) => {
      const result = await httpClient.get<number[]>(
        import.meta.env.VITE_HD_API_URL +
          "/conversation-hub/online-contact-ids",
        {
          params: {
            companyId,
          },
        }
      );
      if (result.status >= 400) {
        throw new Error(result.statusText);
      }
      set(() => ({
        onlineContactIds: result.data,
      }));

      const resultOperators = await httpClient.get<string[]>(
        import.meta.env.VITE_HD_API_URL + "/conversation-hub/online-operators",
        {
          params: {
            companyId,
          },
        }
      );
      if (resultOperators.status >= 400) {
        throw new Error(resultOperators.statusText);
      }
      set(() => ({
        onlineOperators: resultOperators.data,
      }));
    },
    onOnlinePerson: (contactId: number) => {
      const onlineContactIds = [
        ...new Set([...get().onlineContactIds, contactId]),
      ];
      set(() => ({
        onlineContactIds,
      }));
    },
    onOfflinePerson: (contactId: number) => {
      const onlineContactIds = get().onlineContactIds.filter(
        (c) => c !== contactId
      );
      set(() => ({
        onlineContactIds,
      }));
    },
    onOnlineOperator: (username: string) => {
      const onlineOperators = [
        ...new Set([...get().onlineOperators, username]),
      ];
      set(() => ({
        onlineOperators,
      }));
    },
    onOfflineOperator: (username: string) => {
      const onlineOperators = get().onlineOperators.filter(
        (c) => c !== username
      );
      set(() => ({
        onlineOperators,
      }));
    },
  };
});
