import { lazy, Suspense } from "react";
import ReactCountryFlag from "react-country-flag";

import { HdLoader, HdOffcanvas, HdOnlineLed, HdTabs } from "@hd/ui-kit";

import { useGetContactDetails } from "../../api/contacts/useContactDetails";
import { formatDate } from "../../utils/dates";
import { useAuth } from "../auth/auth.store";
import { useChatStore } from "../chat/chat.store";
import { useContactPanel } from "./contact-panel.store";

function ContactPanel() {
  const isOpen = useContactPanel((state) => state.isOpen);
  const close = useContactPanel((state) => state.close);
  const contactId = useContactPanel((state) => state.contactId);
  const selectedTabId = useContactPanel((state) => state.selectedTabId);
  const changeTab = useContactPanel((state) => state.changeTab);
  const { pageCompanyId } = useAuth();
  const onlineContactIds = useChatStore((state) => state.onlineContactIds);

  const contactDetails = useGetContactDetails({ contactId });

  const isOnline = contactId ? onlineContactIds.includes(contactId) : false;

  const ActivityHistoryPanel = lazy(
    () => import("./components/ActivityHistoryPanel")
  );

  const ConversationPanel = lazy(
    () => import("./components/ConversationPanel")
  );

  const ContactCardPanel = lazy(() => import("./components/ContactCardPanel"));
  // react query for contact info
  // react query for contact conversation

  return (
    <HdOffcanvas isOpen={isOpen} position="right" width="600px" onClose={close}>
      {contactDetails.isPending || !contactDetails.data ? (
        <HdLoader></HdLoader>
      ) : (
        <div className="flex flex-col h-full text-bodyText text-xs">
          <div className="pt-8 px-7">
            <h2 className="flex items-center text-sm font-bold">
              <ReactCountryFlag className="mr-2" countryCode="IT" svg />
              <span>
                {contactDetails.data.name} {contactDetails.data.surname}
              </span>
            </h2>
            <div className="mt-1 font-medium">
              <div className="flex items-center">
                <span>utente {isOnline ? "online" : "offline"} ora</span>
                <HdOnlineLed className="ms-1" online={isOnline}></HdOnlineLed>
              </div>
              <div>
                Ultimo soggiorno:
                {contactDetails.data.lastOvernightStay && (
                  <a className="cursor-pointer text-accent font-bold mx-1">
                    {contactDetails.data.lastOvernightStay.companyName},{" "}
                    {formatDate(
                      new Date(contactDetails.data.lastOvernightStay.checkIn)
                    )}
                    , {contactDetails.data.lastOvernightStay.adultsCount}
                    {" adulti "}
                    {contactDetails.data.lastOvernightStay.nightsCount}{" "}
                    {contactDetails.data.lastOvernightStay.nightsCount === 1
                      ? "notte"
                      : "notti"}
                  </a>
                )}
              </div>
              <div>
                Contatti:
                {contactDetails.data.email && (
                  <a className="cursor-pointer text-accent font-bold mx-1">
                    {contactDetails.data.email}
                  </a>
                )}
                -
                {contactDetails.data.mobile && (
                  <a className="cursor-pointer text-accent font-bold mx-1">
                    {contactDetails.data.mobile}
                  </a>
                )}
                -
                {contactDetails.data.phone && (
                  <a className="cursor-pointer text-accent font-bold mx-1">
                    {contactDetails.data.phone}
                  </a>
                )}
              </div>
              <div>Note interne: {contactDetails.data.notes}</div>
            </div>
          </div>
          <HdTabs
            className="flex-grow mt-5 flex flex-col"
            selectedIndex={selectedTabId}
            onChange={changeTab}
          >
            <HdTabs.Header className="-mb-[1px]">
              <HdTabs.Tab className="data-[selected]:border-l data-[selected]:border-r ml-7 relative">
                Conversazione
              </HdTabs.Tab>
              <HdTabs.Tab className="data-[selected]:border-l data-[selected]:border-r relative">
                Storico attività
              </HdTabs.Tab>
              <HdTabs.Tab className="data-[selected]:border-l data-[selected]:border-r relative">
                Scheda persona
              </HdTabs.Tab>
            </HdTabs.Header>
            {contactId && pageCompanyId && (
              <HdTabs.Content className="border-t flex-grow flex flex-col">
                <HdTabs.Panel className=" flex-grow flex flex-col">
                  <Suspense fallback={<HdLoader />}>
                    <ConversationPanel
                      contactId={contactId}
                      companyId={pageCompanyId}
                    ></ConversationPanel>
                  </Suspense>
                </HdTabs.Panel>
                <HdTabs.Panel className="ml-3 flex-grow flex flex-col">
                  <Suspense fallback={<HdLoader />}>
                    <ActivityHistoryPanel
                      contactId={contactId}
                      companyId={pageCompanyId}
                    ></ActivityHistoryPanel>
                  </Suspense>
                </HdTabs.Panel>
                <HdTabs.Panel className="ml-3 flex-grow flex flex-col">
                  <Suspense fallback={<HdLoader />}>
                    <ContactCardPanel
                      companyId={pageCompanyId}
                      contactId={contactId}
                    ></ContactCardPanel>
                  </Suspense>
                </HdTabs.Panel>
              </HdTabs.Content>
            )}
          </HdTabs>
        </div>
      )}
    </HdOffcanvas>
  );
}

export default ContactPanel;
