import clsx from "clsx";
import {
  Button,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuTrigger,
  Popover,
} from "react-aria-components";
import { MdMenu } from "react-icons/md";

interface HdDropdownMenuProps {
  children?: React.ReactNode;
  className?: string;
}

function HdDropdownMenu({ children, className }: HdDropdownMenuProps) {
  return (
    <MenuTrigger>
      <Button
        className={clsx(
          "inline-flex items-center justify-center rounded-md bg-white bg-clip-padding border border-gray-300 px-1 py-1 hover:bg-opacity-30 pressed:bg-opacity-40 transition-colors cursor-pointer outline-none focus-visible:ring-2 focus-visible:ring-white/75",
          className
        )}
      >
        <MdMenu className="text-xl" />
      </Button>
      <Popover
        placement="bottom right"
        className="w-48 overflow-auto bg-white shadow-lg ring-1 ring-black ring-opacity-5 entering:animate-in entering:fade-in entering:zoom-in-95 exiting:animate-out exiting:fade-out exiting:zoom-out-95 fill-mode-forwards origin-top-left"
      >
        <Menu className="outline-none">{children}</Menu>
      </Popover>
    </MenuTrigger>
  );
}

HdDropdownMenu.MenuItem = function HdDropdownMenuItem(props: MenuItemProps) {
  return <MenuItem {...props} />;
};

export default HdDropdownMenu;
