import logo from "../../../assets/logo.svg";

function Footer() {
  const year = new Date().getFullYear();

  return (
    <div className="flex px-3 py-3 align-center border-t border-t-gray-300">
      <div>
        <img style={{ width: "88px" }} src={logo} />
      </div>
      <div className="ml-16 text-sm font-thin flex items-center">
        Copyright {year} - Hoteldoor is a registered Trade Mark - Sviluppato da
        &nbsp;
        <a target="_blank" href="https://www.brg.it/">
          BRG communication
        </a>
      </div>
    </div>
  );
}

export default Footer;
