/* eslint-disable react-refresh/only-export-components */
import { lazy, Suspense } from "react";
import { Route } from "react-router-dom";

import { HdPageLoader } from "@hd/ui-kit";

const LoginLayoutPage = lazy(() => import("./pages/login-layout/LoginLayout"));
const LoginPage = lazy(() => import("./pages/login/LoginPage"));
const ForgotPasswordPage = lazy(
  () => import("./pages/forgot-password/ForgotPasswordPage")
);
const ResetPasswordPage = lazy(
  () => import("./pages/reset-password/ResetPasswordPage")
);

function useLoginRoutes() {
  return (
    <Route
      path="account"
      element={
        <Suspense fallback={<HdPageLoader />}>
          <LoginLayoutPage />
        </Suspense>
      }
    >
      <Route
        path="login"
        element={
          <Suspense fallback={<HdPageLoader />}>
            <LoginPage />
          </Suspense>
        }
      />
      <Route
        path="forgot-password"
        element={
          <Suspense fallback={<HdPageLoader />}>
            <ForgotPasswordPage />
          </Suspense>
        }
      />
      <Route
        path="reset-password/:token"
        element={
          <Suspense fallback={<HdPageLoader />}>
            <ResetPasswordPage />
          </Suspense>
        }
      />
    </Route>
  );
}

export default useLoginRoutes;
